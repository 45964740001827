export const strings = {
  route: 'Ruta',
  point: 'Punto de interese',
  area: 'Área de interese'
};

export const calculateDistance = function(lat1, lng1, lat2, lng2) {
  var RADIUS_EARTH = 6371 * 1000;
  var DEG_TO_RAD = Math.PI / 180;

  var dLat = (lat2 - lat1) * DEG_TO_RAD;
  var dLng = (lng2 - lng1) * DEG_TO_RAD;
  lat1 = lat1 * DEG_TO_RAD;
  lat2 = lat2 * DEG_TO_RAD;

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return RADIUS_EARTH * c;
};
