import React from 'react';
import { Link } from 'gatsby';
import { strings } from '../helpers';

const LocationList = ({
  locations,
  showDistance = false,
  showExtra = false,
  className = ''
}) => {
  return (
    <ul className={`location-list ${className}`}>
      {locations.map((location, i) => {
        return (
          <li key={`location_${i}`}>
            <Link to={location.node.fields.slug}>
              <div className={`icon ${location.node.frontmatter.type}`}>
                {showDistance ? (
                  <span className="distance">
                    {Math.round(location.distance)}m
                  </span>
                ) : null}
              </div>

              <div className="info">
                <span className="type">
                  {strings[location.node.frontmatter.type]}
                </span>
                <p>{location.node.frontmatter.title}</p>
                {showExtra ? (
                  <span className="extra">{location.extra}</span>
                ) : null}
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default LocationList;
