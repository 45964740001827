import React from 'react';
import { graphql } from 'gatsby';

import { HTMLContent } from '../components/content';
import Header from '../components/header';
import LocationList from '../components/locationList';
import { calculateDistance } from '../helpers';

const Place = ({ pageContext, data, location }) => {
  const { current: place, other: other } = data;

  const otherDistance = other.edges.map(o => {
    const [placeLat, placeLon] = place.fields.centroid.coordinates;
    const [otherLat, otherLon] = o.node.fields.centroid.coordinates;
    return {
      ...o,
      distance: calculateDistance(placeLat, placeLon, otherLat, otherLon)
    };
  });

  const otherSorted = otherDistance.sort((a, b) =>
    a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0
  );

  return (
    <article className="page place">
      <Header
        title={place.frontmatter.title}
        back="/lugares"
        path={location.pathname}
      />
      <HTMLContent content={place.html} />
      {other.edges.length ? (
        <>
          <h2>Cerca de aquí</h2>
          <LocationList locations={otherSorted} showDistance={true} />
        </>
      ) : null}
    </article>
  );
};

export default Place;

export const query = graphql`
  query($slug: String!) {
    current: markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        centroid {
          coordinates
        }
      }
      frontmatter {
        title
        intro
        point
        type
      }
      html
    }

    other: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/lugares/" }
        fields: { slug: { ne: $slug } }
        frontmatter: { visible: { eq: true } }
      }
    ) {
      edges {
        node {
          fields {
            slug
            centroid {
              coordinates
            }
          }
          frontmatter {
            title
            type
          }
        }
      }
    }
  }
`;
